import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";

import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import { Figure } from "../../graphic/Figure";
import { Checkbox } from "../../form/Checkbox";
import { Progress } from "../../form/Progress";

export const FileItem = ({
    title,
    type,
    description,
    link,
    href,
    target,
    locked,
    onClick,
    onCheck,
    checked,
    actions,
    selected,
    progress,
    custom,
    indent
}) => {
    // Icon
    let icon = null;
    let iconClass = null;
    switch (type) {
        // Other
        case "return":
            icon = "level-up";
            iconClass = styles.returnIcon;
            break;
        case "folder":
            icon = "doc-folder";
            break;
        case "paper":
            icon = "doc-paper";
            break;

        // Images
        case "image/jpeg":
            icon = "doc-jpg";
            break;
        case "image/jpg":
            icon = "doc-jpg";
            break;
        case "image/png":
            icon = "doc-png";
            break;
        case "image/gif":
            icon = "doc-gif";
            break;
        case "image/svg+xml":
            icon = "doc-svg";
            break;

        // PDF
        case "application/pdf":
            icon = "doc-pdf";
            break;

        // Word
        case "application/msword":
            icon = "doc-word";
            break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            icon = "doc-word";
            break;
        case "application/rtf":
            icon = "doc-word";
            break;
        case "text/rtf":
            icon = "doc-word";
            break;
        case "text/plain":
            icon = "doc-txt";
            break;

        // Spreadsheets
        case "application/vnd.ms-excel":
            icon = "doc-xls";
            break;
        case "application/excel":
            icon = "doc-xls";
            break;
        case "application/x-msexcel":
            icon = "doc-xls";
            break;
        case "application/x-excel":
            icon = "doc-xls";
            break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            icon = "doc-xls";
            break;

        // Presentations
        case "application/vnd.ms-powerpoint":
            icon = "doc-ppt";
            break;
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            icon = "doc-ppt";
            break;

        default:
            icon = "doc-default";
    }

    return (
        <Clickable
            className={classNames(styles.item, {
                [styles.selected]: selected,
                [styles.clickable]: onClick || link || href,
                [styles.indent]: indent
            })}
            title={typeof title === "string" ? title : null}
            link={link}
            href={href}
            target={target}
            onClick={onClick}
        >
            {/* Icon */}
            <div className={classNames(styles.icon, iconClass)}>
                <Figure name={icon} />
                {locked ? (
                    <div title={locked.fullName} className={styles.locked}>
                        <Icon name="lock" />
                    </div>
                ) : null}
            </div>

            {/* Details */}
            {custom ? (
                <div className={styles.custom}>
                    <div className={styles.customWrapper}>{custom}</div>
                </div>
            ) : (
                <div className={styles.details}>
                    {progress === 0 || progress ? (
                        <Progress
                            percent={progress}
                            success={progress >= 100}
                            animation={progress > 0 && progress < 100}
                            spacing={false}
                            size="small"
                        >
                            {title}
                        </Progress>
                    ) : (
                        <React.Fragment>
                            <div className={styles.title}>{title}</div>
                            <div className={styles.description}>{description}</div>
                        </React.Fragment>
                    )}
                </div>
            )}

            {/* Actions */}
            {actions ? (
                <div className={styles.actions}>
                    <div className={styles.wrapper}>{actions}</div>
                </div>
            ) : null}

            {/* Checkbox */}
            {type !== "return" && onCheck ? (
                <div className={styles.checker}>
                    <div className={styles.wrapper}>
                        <Checkbox noMargin checked={checked} onToggle={onCheck} />
                    </div>
                </div>
            ) : null}
        </Clickable>
    );
};
