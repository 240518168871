import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import { Spinner } from "../../loaders/Spinner";

export const FileList = ({ bordered, embedded, busy, children }) => {
    return (
        <div
            className={classNames(styles.list, {
                [styles.bordered]: bordered,
                [styles.embedded]: embedded
            })}
        >
            {children}
            {busy && (
                <div className={styles.loader}>
                    <Spinner />
                </div>
            )}
        </div>
    );
};
