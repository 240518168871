import React from "react";
import styles from "./styles.scss";

import { Spinner } from "../../loaders/Spinner";

export const CheckboxList = ({ busy, children, onItemToggle }) => {
    return (
        <div className={styles.base}>
            {busy ? (
                <div className={styles.loader}>
                    <Spinner primary className={styles.spinner} />
                </div>
            ) : null}

            {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                    onChange: onItemToggle
                })
            )}
        </div>
    );
};
