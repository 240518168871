import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import { Icon } from "../../graphic/Icon";

export const OnOffSwitch = ({ value, disabled, silent, title, onChange, children }) => {
    const toggleSwitch = () => {
        if (disabled) {
            return false;
        }

        // Callback
        if (onChange) {
            onChange(!value);
        }
    };

    return (
        <div
            className={classNames(styles.base, {
                [styles.isOff]: !value,
                [styles.disabled]: disabled,
                [styles.noLabel]: !children,
                [styles.silent]: silent
            })}
            onClick={toggleSwitch}
            title={title}
        >
            <div className={styles.wrapper}>
                <div className={styles.switch}>
                    <div className={styles.handle}></div>
                    <Icon className={styles.onIcon} name="check" size="small" />
                    <Icon className={styles.offIcon} name="close" size="small" />
                </div>

                {/* Label */}
                {children ? <div className={styles.label}>{children}</div> : null}
            </div>
        </div>
    );
};
