import React, { useRef } from "react";
import styles from "./styles.scss";

import Dropzone from "react-dropzone";
import { mimeTypesMap } from "../../../utils/fileTypeUtils";
import { Button } from "../Button";

export const FileSelectButton = ({ children, accept, multiple, onSelect, ...props }) => {
    const dropzone = useRef();

    // Accept file types
    const getAcceptedTypes = () => {
        let acceptTypes = null;
        if (accept) {
            acceptTypes = "";
            const acceptArray = accept.split(",");
            acceptArray.map((typeName) => {
                const type = mimeTypesMap[typeName];
                if (type) {
                    acceptTypes += type + ",";
                }
                return typeName;
            });
            acceptTypes = acceptTypes.slice(0, -1);
        }
        return acceptTypes;
    };

    return (
        <Button onClick={() => dropzone.current.open()} {...props}>
            {children}
            <Dropzone
                className={styles.dropzone}
                ref={dropzone}
                onDrop={(files) => onSelect && onSelect(files)}
                multiple={multiple}
                accept={getAcceptedTypes()}
            />
        </Button>
    );
};
