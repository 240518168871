import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import style from "./style.scss";
import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import { Tabs } from "../../nav/Tabs";
import { disableBodyScroll, enableBodyScroll } from "../../../utils/scrollUtils";

const Popup = ({ type, onClose, size, onClickOutside, children }) => {
    const baseRef = useRef();
    const hasTabs = React.Children.toArray(children)?.some((child) => child?.type === Tabs);

    useEffect(() => {
        const baseElement = baseRef?.current;
        disableBodyScroll(baseElement);

        return () => {
            enableBodyScroll(baseElement);
        };
    }, []);

    const handleClickOutside = (e) => {
        onClickOutside && onClickOutside(e);
    };

    return (
        <div
            ref={baseRef}
            className={classNames(style.base, {
                [style.visible]: true,
                [style.error]: type === "error",
                [style.sizeWrap]: size === "wrap",
                [style.withTabs]: hasTabs
            })}
            onClick={handleClickOutside}
        >
            <div
                className={style.box}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {React.Children.map(children, (child) => {
                    if (child?.type === Tabs) {
                        return React.cloneElement(child, {
                            mode: "popup"
                        });
                    } else {
                        return child;
                    }
                })}

                {onClose ? (
                    <Clickable onClick={onClose} className={style.close}>
                        <Icon name="close" />
                    </Clickable>
                ) : null}
            </div>
        </div>
    );
};

export default Popup;
