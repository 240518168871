import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ScrollableArea } from "../../layout/ScrollableArea";
import style from "./style.scss";

import { Button } from "../../button/Button";
import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import { Menu, MenuItem } from "../Menu";
import { PopOver } from "../PopOver";

export const Tabs = ({ onChange, disabled, mode, activeKey, children }) => {
    const history = useHistory();
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {}, [activeKey]);

    const handleTabClick = (pane) => {
        if (disabled) {
            return;
        }

        if (pane.props.link) {
            history.replace(pane.props.link);
        }

        onChange && onChange(pane.props.tabKey);
        setShowMore(false);
    };

    const isTabActive = (pane) => {
        const { link, tabKey } = pane.props;
        return (
            (link && link === history.location.pathname) ||
            (tabKey && activeKey && tabKey.toString() === activeKey.toString())
        );
    };

    return (
        <div
            className={classNames(style.base, {
                [style.popupMode]: mode === "popup",
                [style.boxMode]: mode === "box",
                [style.overflow]: React.Children.toArray(children)?.length > 3
            })}
        >
            <div className={style.tabs}>
                {/* More button */}
                {React.Children.toArray(children)?.length > 3 && (
                    <div className={style.more}>
                        <PopOver
                            visible={showMore}
                            align="end"
                            layer="nav"
                            onClose={() => setShowMore(false)}
                            content={
                                <Menu>
                                    {React.Children.toArray(children)?.map((pane) => (
                                        <MenuItem
                                            key={pane.props.tabKey}
                                            label={pane.props.label}
                                            icon={pane.props.icon}
                                            onClick={() => handleTabClick(pane)}
                                            selected={isTabActive(pane)}
                                        />
                                    ))}
                                </Menu>
                            }
                        >
                            <Button
                                square
                                size="small"
                                color="silent"
                                icon="more"
                                iconColor="primary"
                                onClick={() => setShowMore(!showMore)}
                            />
                        </PopOver>
                    </div>
                )}

                <div className={style.fadeLeft} />
                <div className={style.fadeRight} />
                <ScrollableArea silent suppressScrollY>
                    <div className={style.wrapper}>
                        {React.Children.toArray(children)?.map((pane) => {
                            if (!pane) {
                                return null;
                            }

                            const { tabKey, icon, label, counter } = pane.props;

                            const tabClasses = classNames(style.tab, {
                                [style.active]: isTabActive(pane)
                            });

                            return (
                                <Clickable key={tabKey} className={tabClasses} onClick={() => handleTabClick(pane)}>
                                    {/* Icon */}
                                    {icon ? (
                                        <span className={style.icon}>
                                            <Icon name={icon} />
                                        </span>
                                    ) : null}

                                    {/* Label */}
                                    <div className={style.label}>{label}</div>

                                    {/* Counter */}
                                    {counter ? (
                                        <span className={style.counter}>
                                            <span className={style.numbers}>{counter}</span>
                                        </span>
                                    ) : null}
                                </Clickable>
                            );
                        })}
                    </div>
                </ScrollableArea>
            </div>

            {/* Tab panes */}
            <div className={style.paneWrapper}>
                {React.Children.toArray(children)?.find((pane) => isTabActive(pane))}
            </div>
        </div>
    );
};
