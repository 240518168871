import React from "react";
import { Alert } from "../../alert/Alert";
import { Box, BoxContent } from "../../box/Box";
import { Text } from "../../typography/Text";
import { Page } from "../Page/Page";
import { PageHeader } from "../PageHeader";

export const PageError = ({ title, message, actions }) => {
    return (
        <Page>
            <PageHeader icon="error-circle" color="error" title={title || "Error"} />
            <Box>
                <BoxContent>
                    <Alert type="error">
                        <Text size="large">{message}</Text>
                    </Alert>
                </BoxContent>
                {actions ? <BoxContent border="top">{actions}</BoxContent> : null}
            </Box>
        </Page>
    );
};
