import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";

import { Checkbox } from "../../form/Checkbox";

export const Item = ({ checked, children, onChange, value, disabled }) => {
    return (
        <div
            className={classNames(styles.item, {
                [styles.checked]: checked
            })}
        >
            <Checkbox
                className={styles.checkbox}
                checked={checked}
                disabled={disabled}
                onToggle={() => !disabled && onChange && onChange(value)}
            >
                {children}
            </Checkbox>
        </div>
    );
};
