import classNames from "classnames";
import React, { useState } from "react";
import style from "./style.scss";

import { Clickable } from "../../button/Clickable";
import { BoxTitle } from "./BoxTitle";
import { Tabs } from "../../nav/Tabs";

export const Box = ({ onClick, link, value, collapsable, fill, className, children, ...props }) => {
    const [collapsed, setCollapsed] = useState(props.collapsed || false);
    return (
        <Clickable
            block
            className={classNames(
                style.base,
                {
                    [style.collapsed]: collapsed,
                    [style.clickable]: onClick || link,
                    [style.fill]: fill
                },
                className
            )}
            onClick={value ? () => onClick(value) : onClick}
            link={link}
        >
            {React.Children.map(children, (child) => {
                if (child && child.type === BoxTitle) {
                    return React.cloneElement(child, {
                        collapsed,
                        onToggleCollapse: collapsable ? () => setCollapsed(!collapsed) : null
                    });
                } else if (child?.type === Tabs) {
                    return React.cloneElement(child, {
                        mode: "box"
                    });
                } else {
                    return child;
                }
            })}
        </Clickable>
    );
};
