import React from "react";
import styles from "./StripeButton.scss";
import config from "../../../config";

export const StripeButton = ({ path }) => {
    return (
        <a
            href={`${config.STRIPE_DASHBOARD_URL}${path}`}
            target="_blank"
            rel="noreferrer"
            title="Open in Stripe"
            className={styles.base}
            onClick={(e) => e.stopPropagation()}
        >
            <svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M155.3 154.6c0-22.3 18.6-30.9 48.4-30.9 43.4 0 98.5 13.3 141.9 36.7V26.1C298.3 7.2 251.1 0 203.8 0 88.1 0 11 60.4 11 161.4c0 157.9 216.8 132.3 216.8 200.4 0 26.4-22.9 34.9-54.7 34.9-47.2 0-108.2-19.5-156.1-45.5v128.5a396.09 396.09 0 0 0 156 32.4c118.6 0 200.3-51 200.3-153.6 0-170.2-218-139.7-218-203.9z" />
            </svg>
        </a>
    );
};
