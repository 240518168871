import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Button } from "../../button/Button";
import { FileSelectButton } from "../../button/FileSelectButton";
import Input from "../../form/Input";
import Select from "../../form/Select";

export default class PageAction extends React.Component {
    render() {
        // Properties
        const { children, noSpacing, alignRight } = this.props;

        let button = null;
        let input = null;

        React.Children.map(children, (child, index) => {
            // Button
            if (child.type === Button || child.type === FileSelectButton) {
                button = React.cloneElement(child, {
                    className: style.button
                });
            }

            // Input
            else if (child.type === Input) {
                input = React.cloneElement(child, {
                    inputClassName: style.input
                });
            }

            // Select
            else if (child.type === Select) {
                input = React.cloneElement(child, {
                    selectClassName: style.input
                });
            }
        });

        // Class names
        const classes = classNames(style.base, {
            [style.hasInput]: input !== null,
            [style.noSpacing]: noSpacing,
            [style.alignRight]: alignRight
        });

        return (
            <div className={classes}>
                {input ? <div className={style.inputWrapper}>{input}</div> : null}
                {button}
            </div>
        );
    }
}
