import React from "react";
import { FormattedNumber } from "react-intl";

export const ReadableFileSize = ({ sizeInBytes }) => {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let unitIndex = 0;
    let sizeValue = sizeInBytes;

    while (sizeValue >= 1024 && unitIndex < units.length - 1) {
        sizeValue /= 1024;
        unitIndex++;
    }

    return (
        <>
            <FormattedNumber value={sizeValue} style={`decimal`} minimumFractionDigits={0} maximumFractionDigits={2} />{" "}
            {units[unitIndex]}
        </>
    );
};
