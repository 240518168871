import moment from "moment";
import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { Button } from "../../../button/Button";
import { ConfirmActionButton } from "../../../button/ConfirmActionButton";
import { CustomSelect, OptionItem } from "../../../form/CustomSelect";
import Form from "../../../form/Form";
import { FormItem } from "../../../form/FormItem";
import { Textarea } from "../../../form/Textarea";
import { KeyValueList, Item as KvItem } from "../../../list/KeyValueList";
import Badge from "../../../misc/Badge";
import { TabPane, Tabs } from "../../../nav/Tabs";
import { Popup, PopupButtons, PopupContent, PopupTitle } from "../../../notify/Popup";
import Section from "../../../section/Section";
import { Text } from "../../../typography/Text";

const msgKey = "riskTable";

export const EditHazardPopup = ({ onClose, onSave, onDelete, ...props }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [hazard, setHazard] = useState(props.hazard);
    const [busySaving, setBusySaving] = useState(false);
    const [busyDeleting, setBusyDeleting] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const handleChangeHazardProp = (key, value) => {
        setUnsavedChanges(true);
        if (key === "description") {
            setHazard({
                ...hazard,
                description: value
            });
        } else {
            setHazard({
                ...hazard,
                assessment: {
                    ...hazard.assessment,
                    [key]: value
                }
            });
        }
    };

    const handleSaveHazard = () => {
        setBusySaving(true);
        onSave(hazard, () => {
            setUnsavedChanges(false);
            onClose();
        });
    };

    const handleDeleteHazard = () => {
        setBusyDeleting(true);
        onDelete(hazard, () => {
            onClose();
        });
    };

    return (
        <Popup onClose={onClose}>
            <PopupTitle>
                <FormattedMessage id={`${msgKey}.hazard`} />
            </PopupTitle>
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <TabPane tabKey={1} icon="edit" label={<FormattedMessage id={`${msgKey}.edit`} />}>
                    <PopupContent>
                        <Form>
                            <FormItem compact label={<FormattedMessage id={`${msgKey}.hazard`} />}>
                                {hazard.common ? (
                                    <Text>{hazard.description}</Text>
                                ) : (
                                    <Textarea
                                        value={hazard.description}
                                        onChange={(e) => handleChangeHazardProp("description", e.target.value)}
                                    />
                                )}
                            </FormItem>
                            <FormItem compact label={<FormattedMessage id={`${msgKey}.measures`} />}>
                                <Textarea
                                    value={hazard?.assessment?.measures}
                                    onChange={(e) => handleChangeHazardProp("measures", e.target.value)}
                                />
                            </FormItem>
                            <FormItem label={<FormattedMessage id={`${msgKey}.riskAssessment`} />} compact>
                                <CustomSelect
                                    value={!isNaN(hazard.assessment?.rank) ? hazard.assessment.rank : null}
                                    onChange={(val) => handleChangeHazardProp("rank", val)}
                                    placeholder={<FormattedMessage id={`${msgKey}.selectAssessment`} />}
                                >
                                    <OptionItem
                                        value={0}
                                        symbol={
                                            <Badge fill color="silent">
                                                <FormattedMessage id={`${msgKey}.rank-none`} />
                                            </Badge>
                                        }
                                    />
                                    <OptionItem
                                        value={1}
                                        symbol={
                                            <Badge fill color="rank-low">
                                                <FormattedMessage id={`${msgKey}.rank-low`} />
                                            </Badge>
                                        }
                                    />
                                    <OptionItem
                                        value={2}
                                        symbol={
                                            <Badge fill color="rank-mid">
                                                <FormattedMessage id={`${msgKey}.rank-mid`} />
                                            </Badge>
                                        }
                                    />
                                    <OptionItem
                                        value={3}
                                        symbol={
                                            <Badge fill color="rank-high">
                                                <FormattedMessage id={`${msgKey}.rank-high`} />
                                            </Badge>
                                        }
                                    />
                                </CustomSelect>
                            </FormItem>
                        </Form>
                    </PopupContent>
                    <PopupButtons>
                        <Button
                            type="submit"
                            color="primary"
                            icon="check"
                            onClick={handleSaveHazard}
                            busy={busySaving}
                            disabled={
                                !hazard.description?.length ||
                                hazard.assessment?.rank === null ||
                                isNaN(hazard.assessment?.rank) ||
                                busyDeleting ||
                                !unsavedChanges
                            }
                        >
                            <FormattedMessage id={`${msgKey}.saveAssessment`} />
                        </Button>
                    </PopupButtons>
                </TabPane>
                <TabPane tabKey={2} icon="info" label={<FormattedMessage id={`${msgKey}.details`} />}>
                    <PopupContent>
                        {hazard.assessment ? (
                            <Section>
                                <KeyValueList>
                                    {hazard.assessment.edited ? (
                                        <KvItem label={<FormattedMessage id={`${msgKey}.editedDate`} />}>
                                            {moment(hazard.assessment.edited).format("LLL")}
                                        </KvItem>
                                    ) : null}

                                    {hazard.assessment.editedByUser ? (
                                        <KvItem label={<FormattedMessage id={`${msgKey}.editedBy`} />}>
                                            {hazard.assessment.editedByUser.fullName}
                                        </KvItem>
                                    ) : null}

                                    {hazard.assessment.created ? (
                                        <KvItem label={<FormattedMessage id={`${msgKey}.registeredDate`} />}>
                                            {moment(hazard.assessment.created).format("LLL")}
                                        </KvItem>
                                    ) : null}

                                    {hazard.assessment.createdByUser ? (
                                        <KvItem label={<FormattedMessage id={`${msgKey}.registeredBy`} />}>
                                            {hazard.assessment.createdByUser.fullName}
                                        </KvItem>
                                    ) : null}
                                </KeyValueList>
                            </Section>
                        ) : null}

                        {/* Delete hazard button */}
                        <ConfirmActionButton
                            fill
                            busy={busyDeleting}
                            label={<FormattedMessage id={`${msgKey}.deleteRiskHazard`} />}
                            question={<FormattedMessage id={`${msgKey}.deleteRiskHazardConfirmation`} />}
                            onConfirm={handleDeleteHazard}
                        />
                    </PopupContent>
                </TabPane>
            </Tabs>
        </Popup>
    );
};
